$(document).ready(function() {
    setTimeout(function() {
        $('body').addClass('container-loaded');
    }, 100);

    setMenu();

    $(window).scroll(function() {
        setMenu();
    });

    function setMenu() {
        if ($(window).width() > 768) {
            if ($(window).scrollTop() > 50) {
                $('.navbar-index').addClass('navbar-fixed-top');
            }

            if ($(window).scrollTop() < 51) {
                $('.navbar-index').removeClass('navbar-fixed-top');
            }
        } else {
            $('.navbar-index').addClass('navbar-fixed-top');
        }
    }

    //button for scroll down to contacts form
    $(".contacts-redirect").on('click', function() {
        $('html, body').animate({
            scrollTop: $("#contacts").offset().top - 55
        }, 800);
    });

    //-------- Industry slider -----///

    if ($(window).width() > 768) {
        $('.industry-slide').hover(
            function() {
                var elem = $('.industries');
                var defaultText = elem.find('.i-default-text');
                var description = $(this).data('description');

                defaultText.stop().css('visibility', 'hidden');
                elem.find('.i-text-' + description).stop().fadeIn();
            },
            function() {
                var elem = $('.industries');
                var defaultText = elem.find('.i-default-text');

                elem.find('.i-text').stop().hide();
                defaultText.stop().css('visibility', 'visible');
            }
        );
    }

    //-------- Index main slider -----///

    $('.main-slider').slick({
        dots: true,
        infinite: true,
        speed: 500,
        fade: true,
        pauseOnHover: false,
        pauseOnDotsHover: true,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: true,
        nextArrow: '<button type="button" class="slick-next"></button>',
        prevArrow: '<button type="button" class="slick-prev"></button>',
        appendArrows: '.main-slider-container',
    });

    //-------- Slider -----///

    if ($(window).width() < 768) {
        $('.block-slider').slick({
            infinite: true,
            slidesToShow: 1,
            dots: false,
            autoplay: false,
            fade: true,
            arrows: true,
            nextArrow: '<button type="button" class="slick-next"></button>',
            prevArrow: '<button type="button" class="slick-prev"></button>',
        });
    }

    $('.study-slider').slick({
        infinite: true,
        slidesToShow: 1,
        dots: false,
        autoplay: false,
        fade: true,
        arrows: true,
        nextArrow: '<button type="button" class="slick-next"></button>',
        prevArrow: '<button type="button" class="slick-prev"></button>',
    });

    $('.mobile-navigation .navbar-toggle').click(function() {
        $(this).toggleClass('open');
    });

    $(function() {
        $('a[href*="#"]:not([href="#"])').click(function() {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html, body').animate({
                        scrollTop: target.offset().top - 55
                    }, 1000);
                    return false;
                }
            }
        });
    });

    if ($(window).width() > 768) {
        $('.equal').matchHeight();
    }
});
